.singleSliderWrapper {
  height: 465px;

  @media(max-width: 768px) {
    height: 400px;
  }

  @media(max-width: 600px) {
    height: 300px;
  }

  & .videoLoader {
    width: 100%;
    height: 100%;
    background-color: #d1dae1;
    animation: 1.5s shine linear infinite;
    position: relative;

    @media(max-width: 768px) {
      height: 400px;
    }

    @media(max-width: 600px) {
      height: 300px;
    }

    &:after {
      content: '';
      background-color: #fff;
      position: absolute;
      left: -67px;
      right: 0;
      top: -17px;
      bottom: 0;
      z-index: 2;
      width: 136px;
      transform: rotate(8deg);

      @media (max-width: 1200px) {
        transform: rotate(0deg);
        background-size: cover;
        background-repeat: no-repeat;
        content: '';
        position: absolute;
        height: 7vw;
        max-height: unset;
        width: 100%;
        left: 0rem;
        bottom: -5px;
        top: unset;
        background-position: center;
        background-color: transparent;
        background-image: url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='375' height='13' viewBox='0 0 375 13' fill='none'%3E%3Cpath d='M375 13L375 0L0 13L0 13L375 13Z' fill='%23FFFFFF'/%3E%3C/svg%3E);
      }
    }
  }
}

