@import '../../../../styles/customMediaQueries.css';

.baseColumn {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 32px;
}

.oneColumn {
  composes: baseColumn;
}

.twoColumns {
  composes: baseColumn;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, calc((100% - 32px) / 2));
  }
}

.threeColumns {
  composes: baseColumn;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 2 * 32px) / 3));
  }
}

.fourColumns {
  composes: baseColumn;

  @media (min-width:576px) {
    grid-template-columns: repeat(2, calc((100% - 2 * 16px) / 2));
  }

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, calc((100% - 3 * 24px) / 3));
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(4, calc((100% - 3 * 32px) / 4));
  }
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.benefitWrapper {
  max-width: 100%;
  margin: 0 auto;
  padding: 32px;

  @media(max-width: 767px) {
    padding: 0 20px;
  }
}

.desktopSlider {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  &>div {
    width: calc(50% - 10px);

    &:nth-child(1) {
      order: 2;
    }

    &:nth-child(2) {
      order: 1;
    }

    &:nth-child(3) {
      order: 3;
    }

    &:nth-child(4) {
      order: 4;
    }

    &:nth-child(5) {
      order: 6;
    }

    &:nth-child(6) {
      order: 5;
    }
  }

  @media(max-width: 768px) {
    display: none;
  }
}

.mobileSlider {
  display: none;

  @media(max-width: 768px) {
    display: block;
  }
}

.videoAndHeadingWrapper {
  margin: 0 auto;
  max-width: var(--contentMaxWidthPages);

  & .slideVideo {
    padding: 8px;
    height: 300px;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #d1dae1;
    box-shadow: 0 1px 2px 0 #d1dae1;

    @media(max-width: 1024px) {
      height: 224px;
      border: 1px solid #fff;
    }

    & video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & .slideData {
    height: 300px;
    padding: 10px 60px;
    border-radius: 4px;
    flex-direction: column;
    justify-content: center;
    display: flex !important;
    background-color: #ffffff;
    border: 1px solid #d1dae1;
    box-shadow: 0 1px 2px 0 #d1dae1;

    @media(max-width: 1024px) {
      padding: 10px;
      height: 224px;
      border: 1px solid #fff;
    }

    @media(max-width: 768px) {
      margin: 20px 0;
    }

    & .indexCount {
      width: 50px;
      height: 50px;
      display: flex;
      color: #fff;
      font-size: 26px;
      border-radius: 50%;
      margin-bottom: 10px;
      align-items: center;
      justify-content: center;
      background: var(--marketplaceColor);

      @media(max-width: 1024px) {
        width: 30px;
        height: 30px;
        font-size: unset;
      }
    }

    & .slideHeading {
      color: #000;
      font-size: 24px;
      font-weight: 600;
      line-height: 46px;

      @media(max-width: 1024px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    & .slideSubHeading {
      font-size: 18px;
      color: #6d6d6d;
      font-weight: 400;
      line-height: normal;

      @media(max-width: 1024px) {
        font-size: 14px;
      }
    }
  }

  & :global(.slick-slide) {
    &>div {
      margin: 0 10px;
    }
  }

  & :global(.slick-arrow) {
    z-index: 1;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex !important;
    background-color: #d3d3d3;

    & svg {
      & path {
        fill: #000;
      }
    }

    &:hover {
      background-color: #000;

      & svg {
        & path {
          fill: #fff;
        }
      }
    }

    &::before {
      display: none;
    }
  }

  & :global(.slick-disabled) {
    display: none !important;
  }

  & :global(.slick-track) {
    display: flex;
    align-items: center;
  }
}

.ideaBox {
  text-align: center;

  & h2 {
    font-size: 32px;
    margin: 0;
    line-height: 1.33;
    font-weight: bold;
    color: #b25f87;

    @media(max-width:500px) {
      font-size: 20px;
    }
  }

  & p {
    font-size: 18px;
    line-height: 1.66;
    font-weight: 500;
    color: #4a4a4f;
    padding: 0 24px;

    @media(max-width:500px) {
      font-size: 14px;
    }
  }
}


.servicesGrid {
  gap: 32px;
  display: grid;
  margin: 0 auto;
  grid-template-columns: repeat(4, 1fr);
  max-width: var(--contentMaxWidthPages);

  @media(max-width: 1160px) {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 32px;
  }

  @media(max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media(max-width: 767px) {
    padding: 0 20px;
  }

  @media(max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  &>div {
    @media(max-width: 500px) {
      min-width: 100%;
    }

  }

  & img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-radius: 8px;

    @media (max-width:500px) {
      height: 200px;
    }
  }

  & .servicesHeading {
    margin-top: 20px;
    line-height: 1.33;
    font-weight: bold;
    color: var(--colorGrey900);
  }
}