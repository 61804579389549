.multiSliderWrapper {
    gap: 20px;
    /* height: 224px; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media(max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
    }

    /* @media(max-width: 1600px) {
        grid-template-columns: repeat(5, 1fr);

        &>div {
            &:nth-child(1) {
                display: none;
            }
        }
    }

    @media(max-width: 1300px) {
        grid-template-columns: repeat(4, 1fr);

        &>div {
            &:nth-child(1) {
                display: none;
            }

            &:nth-child(2) {
                display: none;
            }
        }
    }

    @media(max-width: 960px) {
        grid-template-columns: repeat(3, 1fr);

        &>div {
            &:nth-child(1) {
                display: none;
            }

            &:nth-child(2) {
                display: none;
            }

            &:nth-child(3) {
                display: none;
            }
        }
    }

    @media(max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);

        &>div {
            &:nth-child(1) {
                display: none;
            }

            &:nth-child(2) {
                display: none;
            }

            &:nth-child(3) {
                display: none;
            }

            &:nth-child(4) {
                display: none;
            }
        }
    } */

    & .videoLoader {
        height: 300px;
        border-radius: 4px;
        background-color: #d1dae1;

        @media(max-width: 1024px) {
            height: 224px;
        }
    }

    & .contentLoader {
        height: 300px;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media(max-width: 1024px) {
            height: 224px;
        }

        & .heading {
            height: 65px;
            background-color: #d1dae1;
        }

        & .subHeading {
            height: 70%;
            background-color: #d1dae1;

            @media(max-width: 1024px) {
                height: 60%;
            }
        }
    }
}