@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;
  width: 100%;

  @media (--viewportMedium) {
    padding: 64px 0;
    width: 100%;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

:global(#browse-by-culture) {
  & header {
    display: block;
  }

  & p {
    text-align: center;
    max-width: 100%;

    @media(max-width:500px) {
      font-size: 14px;
    }
  }

  & h2 {
    text-align: center;
    max-width: 100%;
    color: var(--marketplaceColor);
  }

  @media (max-width: 767px) {
    & h2 {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      max-width: 100%;

      @media(max-width:500px) {
        font-size: 20px;

      }
    }


  }
}

:global(#how-it-works-hero) {
  & header {
    display: block;

    & h2 {
      font-size: 1.875em;
      margin: 0;
      padding: 0;
      max-width: 100%;
      text-align: center;

      @media (max-width: 767px) {
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: 600;
      }
    }

    & p {
      font-size: 3.75em;
      margin: 0;
      padding: 0;
    }

    &~div {
      padding-top: 0;

      & p {
        max-width: 570px;
        text-align: center;
        color: #9da0b6 !important;
        font-size: 1.15rem;
        margin: 0 auto;

        @media (max-width: 767px) {
          font-size: 16px;
        }
      }

      & a {
        color: #fff !important;
        box-shadow: 0 3px 6px 0 rgba(45, 122, 241, 0.3);
        background-color: var(--marketplaceColor);
        display: block;
        width: max-content;
        margin: 30px auto;
        padding: 0.5rem 1rem;
        font-size: 1.25rem;
        line-height: 1.5;
        border-radius: 0.3rem;
      }
    }
  }
}

:global(#pricing-hero) {
  & h3 {
    font-size: 1.875em;
    margin: 0;
    padding: 0 0 20px;
    max-width: 100%;
    text-align: center;
  }

  & p {
    font-size: 3.75em;
    margin: 0;
    padding: 0;
  }

  & p {
    max-width: 570px;
    text-align: center;
    color: #9da0b6 !important;
    font-size: 1.25rem;
    margin: 0 auto;
  }

  & a {
    color: #fff !important;
    box-shadow: 0 3px 6px 0 rgba(45, 122, 241, 0.3);
    background-color: var(--marketplaceColor);
    display: block;
    width: max-content;
    margin: 30px auto;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }
}

.informationContainer {
  padding: 0 24px;
  margin-bottom: 40px;

  & p {
    font-size: 14px;
    line-height: 24px;
    color: #6c7191 !important;
  }

  @media (max-width: 768px) {
    padding: 0 24px;
  }
}

.title {
  font-size: 24px;
  line-height: 34px;
}

.answer {
  font-size: 14px;
  line-height: 24px;
  color: #6c7191 !important;
}

.faqContent {
  /* display: grid;
  grid-template-columns: repeat(1, 1fr); */
  /* column-gap: 0px;
  row-gap: 0px; */
  /* max-width: 1000px;
  margin: 0 auto; */
  width: 100%;
  display: flex;
  margin: 0 auto;
  gap: 40px;
  max-width: var(--contentMaxWidthPages);
  align-items: flex-start;

  @media(max-width: 1024px) {
    flex-wrap: wrap;
    gap: 40px;
  }

  & .sliderWrapper {
    width: 40%;
    margin: 0 auto;

    @media(max-width: 600px) {
      width: 90%;
    }

    & .slideVideo {
      height: 100%;

      & img {
        width: 100%;
    height: 400px;
    object-fit: cover;

    @media(max-width:1024px) {
      height: 100%;
    }
      }

      & video {
        width: 100%;
        height: 514px;
        object-fit: cover;
      }
    }
  }

  & .faqText {
    position: relative;
    width: 60%;

    @media(max-width: 1024px) {
      width: 100%;
    }

    /* &:last-child{
       & svg{
          top: 34px;
          @media (max-width: 767px) {
            top: 28px;
          }
        }
      } */
    & svg {
      position: absolute;
      top: 23px;
      right: 21px;

      @media (max-width: 767px) {
        top: 28px;
        right: 15px;
      }
    }
  }

  & :global(.is-open) {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      height: 24px;
      width: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12H19' stroke='%233D70A1' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      right: 12px;
    }
  }

  & :global(.is-closed) {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      height: 24px;
      width: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5V19' stroke='%23949494' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 12H19' stroke='%23949494' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      right: 12px;
    }
  }

  & :global(.Collapsible__trigger) {
    /* composes: h1 from global; */
    display: block;
    margin-bottom: 0px;
    padding: 10px 64px 0px 18px;
    color: var(--black-shade-text-black, #272727);
    font-family: Nunito;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 233.333% */
    height: auto;
    display: flex;
    align-items: center;
    cursor: pointer;

    @media (max-width: 1300px) {
      padding: 15px 48px 0px 18px;
    }

    @media (max-width: 767px) {
      margin-bottom: 12px;
      padding: 15px 44px 0px 12px;
      height: 54px;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
    }
  }

  & :global(.Collapsible) {
    height: unset;
  }

  & :global(.Collapsible__contentInner) {
    margin: 0 15px;
    padding-bottom: 16px;
    color: var(--black-shade-para-text, #949494);
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-bottom: 1px solid #dfdfdf;

    word-break: break-word;

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.heroSlider {
  max-width: 55%;
  overflow: hidden;

  @media (max-width: 1200px) {
    max-width: 100%;
  }
}

:global(#section-2) {
  & p {
    font-size: 16px;
  }
}

:global(#ideas) {
  & header~div>div {
    cursor: pointer;
  }

  & header {
    max-width: 100%;

    & h2 {
      max-width: 100%;
    }

    & p {
      max-width: 100%;
    }

    @media (max-width: 767px) {
      display: none;

      & h2 {
        text-align: center;
      }

      & p {
        text-align: center;
      }
    }
  }

  & h3 {
    font-size: 22px;

    @media (max-width: 767px) {
      font-size: 17px;
      font-weight: 700;
      text-align: center;
      margin-top: 8px;
    }
  }
}

:global(#about-us-1) {
  & header {
    display: block;

    & p {
      text-align: center;
      max-width: 100%;
    }
  }

  & h1 {
    @media (max-width: 767px) {
      max-width: 100%;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
    }
  }
}

:global(#privacy_policy) {
  & header {
    display: block;
  }

  & h1 {
    @media (max-width: 767px) {
      max-width: 100%;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
    }
  }
}

:global(#terms-and-service) {
  & header {
    display: block;
  }

  & h1 {
    @media (max-width: 767px) {
      max-width: 100%;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
    }
  }
}

:global(#details-hero) {
  & header {
    display: block;

    & h2 {
      text-align: center;
      max-width: 100%;

      @media (max-width: 767px) {
        max-width: 100%;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}

:global(#landing-hero) {
  width: 48%;
  /* max-height: 70vh; */

  @media (max-width:1200px) {
    width: 100%;
    order: 0;
    max-height: 100%;
  }
}

:global(#hero-carousel) {
  width: 52%;
  /* max-height: 70vh; */
  background-color: #fff;

  @media (max-width:1200px) {
    width: 100%;
    order: -1;
  }

  &>div {
    padding: 0 !important;
    height: 100%;

    &>div {
      padding: 0 !important;
      height: 100%;
    }
  }
}

:global(#hero-carousel-slider) {
  height: 100%;
  width: 100%;
}

.ideaWrapper {
  & header {
    display: none;
  }

  /* & div {
    &:last-child {
      display: none;
    }
  } */
}

.aboutImageSection {
  position: relative;
  width: 100%;
  padding-bottom: 64px;
}

:global(#job-related) {
  &>div {
    &>div {
      &>div {
        @media(max-width: 768px) {
          &:nth-child(1) {
            order: 2;
          }

          &:nth-child(2) {
            order: 1;
          }
        }
      }
    }
  }
}

:global(#credits) {
  &>div {
    &>div {
      &>div {
        @media(max-width: 768px) {
          &:nth-child(1) {
            order: 2;
          }

          &:nth-child(2) {
            order: 1;
          }
        }
      }
    }
  }
}

:global(#discoverability) {
  &>div {
    &>div {
      &>div {
        &:nth-child(1) {
          @media(max-width: 768px) {
            order: 2;
          }
        }
      }
    }
  }
}

:global(#description) {
  background: #eee;
  padding: 30px 0;
}