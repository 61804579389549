.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  max-width: calc(99vw - 0px);
  /* Hero specific */
  /* min-height: 80vh; */
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  /* overflow: hidden; */
  display: flex;
  flex-direction: row-reverse;
  gap: 58px;
  background-color: #fff;
  @media (max-width: 1200px) {
    flex-direction: column;
    min-height: auto;
    gap: 0;
    max-width: calc(100vw - 0px);
    align-items: flex-start;
    justify-content: flex-start;
  }
  @media (max-width: 767px) {
    /* min-height: 600px; */
  }
  & img {
    max-height: 900px;
    height: 70vh;
    object-fit: cover;
    @media (max-width: 767px) {
      height: 100%;
    }
  }
  & > div {
    /* &:last-child {
      width: 45%;
      @media (max-width: 1600px) {
        width: 58%;
      }
      @media (max-width: 1200px) {
        min-width: 100%;
        width: 100%;
        padding: 34px 0;
      }
      @media (max-width: 767px) {
        padding: 16px 0 34px;
      }
    } */
    &:first-child {
      position: relative !important;
      /* width: 55%;
      @media (max-width: 1600px) {
        width: 50%;
      } */
      /* @media (max-width: 1200px) {
        width: 100%;
        max-height: 500px;
        overflow: hidden;
      }
      @media (max-width: 576px) {
        max-height: 310px;
        overflow: hidden;
      }
      &:after {
        content: '';
        background-color: #fff;
        position: absolute;
        left: -55px;
        right: 0;
        top: -17px;
        bottom: 0;
        z-index: 2;
        width: 130px;
        transform: rotate(8deg);
        @media (max-width: 1200px) {
          transform: rotate(0deg);
          background-size: cover;
          background-repeat: no-repeat;
          content: '';
          position: absolute;
          height: 7vw;
          max-height: unset;
          width: 100%;
          left: 0rem;
          bottom: -3px;
          top: unset;
          background-position: center;
          background-color: transparent;
          background-image: url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='768' height='45' viewBox='0 0 768 45' fill='none'%3E%3Cpath d='M768 45L768 0L0 45L0 45L768 45Z' fill='%23FFFFFF'/%3E%3C/svg%3E);
         
        }
        @media (max-width: 767px) {
          content: unset !important;
        }
      } */
    }
  }
  /* &:after{
    content: "";
    position: absolute;
    left: 90%;
    
    bottom: -100%;
    top: -100%;
    right: -30%;
    background: hsl(331, 34%, 65%);
    transform: rotate(45deg);
    z-index: 1;

  } */
  & > div {
    &:first-child {
      &:after {
        &:after {
        }
      }
    }
  }
  & header {
    position: relative;
    display: block;
    /* z-index: 100; */
    @media (mub-width: 1201px) {

      padding-right: 0;
    }
    & > h1 {
      font-size: 2.75em;
      color: #000 !important;
      text-align: left;
      @media (max-width: 1200px) {
        /* color: #fff !important; */
        font-size: 2em;
      }
    }
    & > p {
      color: #000 !important;
      font-size: 32px;
      font-weight: 600;
      text-align: left;
      @media (max-width: 1200px) {
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        /* color: #fff !important; */
      }
    }
  }
}

:global(#section-4) {
  & h3 {
    font-size: 18px;
  }
}

:global(#section-1) {
}

:global(#section-3) {
  & img {
    /* border-radius: 50%; */
  }
  & h3 {
    font-size: 20px;
    max-width: 100%;
    text-align: center;
  }
}
