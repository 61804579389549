@import '../../../../styles/customMediaQueries.css';

.carouselContainer {
  display: flex;
  align-items: center;
  scroll-behavior: smooth;
  padding: 64px 0 0 0;

  @media (max-width: 767px) {
    padding: 35px 0 0 0;
  }

  /* &:hover .carouselArrows {
    opacity: 1;
  } */

  /* smartphones, touchscreens: we don't need to show arrows */
  /* @media (any-hover: none) and (pointer: coarse) {
    &:hover .carouselArrows {
      opacity: 0;
    }
  } */
}

.carouselArrows {
  /* opacity: 0; */
  z-index: 2;
  transition: all ease-in-out 500ms;
}

.carouselArrowPrev,
.carouselArrowNext {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 2;

  width: 48px;
  height: 48px;
  padding: 0px 0 4px 0;
  margin-top: -64px;
  border-radius: 100%;
  border: none;

  background-color: lightgrey;
  opacity: 0.9;
  font-size: 30px;
  color: black;
  transition: all ease-in-out 100ms;

  @media (max-width:767px) {
    width: 36px;
    height: 36px;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
    background: black;
    color: white;
  }
}

.carouselArrowPrev {
  left: 48px;

  @media (max-width:767px) {
    left: 28px;
  }
}

.carouselArrowNext {
  right: 48px;

  @media (max-width:767px) {
    right: 28px;
  }
}

.notEnoughBlocks {
  @media (--viewportMedium) {
    display: none;
  }
}

.baseCarousel {
  display: flex;
  flex-wrap: nowrap;
  width: var(--carouselWidth);
  padding-bottom: 24px;

  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
}

.oneColumn,
.twoColumns,
.threeColumns,
.fourColumns {
  composes: baseCarousel;
}

.threeColumns {
  &>div {
    &>div {
      &:first-child {
        @media(max-width:500px) {
          width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 1);
        }
      }
    }
  }
}

.block {
  flex: 0 0 auto;
  /* 64px = horizontal layout paddings */
  width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px);

  margin-right: 16px;
  scroll-snap-align: center;

  transform: translateX(32px);

  &:last-of-type {
    padding-right: 32px;
    /* 32px (padding-right above) */
    width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px);

    @media (max-width: 767px) {
      width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px);
      padding-right: 0;
    }
  }

  @media (--viewportMedium) {
    margin-right: 32px;
  }

  @media (min-width: 1200px) {
    /* Offset the start of the carousel so it follows the global grid layout (e.g. (1400 - 1200) / 2 = 100px) */
    /* Removing this uses the full page width for the slider pane */
    /* transform: translateX(
      max(calc((var(--carouselWidth) - var(--contentMaxWidthPages)) / 2 + 32px), 0px)
    ); */
    transform: translateX(32px);

    &:last-of-type {
      padding-right: 32px;
      width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px);
      /* 32px (padding-right above) */
    }
  }
}

.oneColumn .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) */
    width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px);

    &:last-of-type {
      /* 32px (padding-right) */
      width: calc(min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px);
    }
  }
}

.twoColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 32px (one gutter á 32px) / 2 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 32px) / 2);

    &:last-of-type {
      /* 32px (padding-right above) / 2 (number of columns) */
      width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 2);
    }
  }
}

.threeColumns .block {
  width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 2);

  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 32px (two gutters á 32px) / 3 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 64px) / 3);

    &:last-of-type {
      /* 32px (padding-right above) / 3 (number of columns) */
      width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 3);
    }
  }
}

.fourColumns .block {
  @media (--viewportMedium) {
    /* 64px (horizontal layout paddings) - 96px (three gutters á 32px) / 4 (number of columns) */
    width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 64px - 96px) / 4);

    &:last-of-type {
      /* 32px (padding-right above) / 4 (number of columns) */
      width: calc((min(var(--contentMaxWidthPages), var(--carouselWidth)) - 32px) / 4);
    }
  }
}

:global(#section-2-container) {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

:global(#hero-carousel) {
  & .carouselArrows {
    display: none !important;
  }
}

.heroSlider {
  max-width: 51vw;
  overflow: hidden;
  height: 100%;
  position: relative;
  background-color: #fff;

  @media (max-width: 1200px) {
    max-width: 99.9vw;
  }

  &:after {
    content: '';
    background-color: #fff;
    position: absolute;
    left: -67px;
    right: 0;
    top: -17px;
    bottom: 0;
    z-index: 2;
    width: 136px;
    transform: rotate(8deg);

    @media (max-width: 1200px) {
      transform: rotate(0deg);
      background-size: cover;
      background-repeat: no-repeat;
      content: '';
      position: absolute;
      height: 7vw;
      max-height: unset;
      width: 100%;
      left: 0rem;
      bottom: -5px;
      top: unset;
      background-position: center;
      background-color: transparent;
      background-image: url(data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='375' height='13' viewBox='0 0 375 13' fill='none'%3E%3Cpath d='M375 13L375 0L0 13L0 13L375 13Z' fill='%23FFFFFF'/%3E%3C/svg%3E);
    }

    @media (max-width: 768px) {
      /* content: unset !important; */
      height: 10vw;
    }
  }

  & .videoBox {
    height: 460px;
    position: relative;

    @media(max-width: 768px) {
      height: 400px;
    }

    @media(max-width: 600px) {
      height: 300px;
    }

    & video {
      width: 100%;
      object-fit: cover;
      height: 465px;

      @media(max-width: 768px) {
        height: 400px;
      }

      @media(max-width: 600px) {
        height: 300px;
      }

      /* @media screen and (min-width: 575px) and (max-width: 768px) {
        width: 100%;
        height: 500px;
        min-height: 500px;
      } */
    }


    & img {
      width: 100%;
    }

    & :global(.slick-slider) {
      height: 100%;
    }

    & :global(.slick-list) {
      height: 100%;
    }

    & :global(.slick-track) {
      height: 100%;
    }

    & :global(.slick-slide) {
      height: 100%;
      /* width: 100% !important; */

      &>div {
        height: 100%;

        &>div {
          height: 100%;

          & img {
            height: 99.5%;
            object-fit: cover;
          }
        }
      }
    }
  }
}