@import '../../../../../styles/customMediaQueries.css';

.mobileInputRoot {
  width: 100%;
}

.desktopInputRoot {
  background-color: #fff;
  flex: 1;
  padding: 0 16px;

  & input {
    padding: 16px;
  }
}

.mobileIcon {
  margin-left: 24px;
  padding: 11px 0 17px 0;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--colorWhite);

  @media (--viewportMedium) {
    margin-left: 0;
  }
}

.fontInput {
  font-weight: var(--fontWeightBlack);
  font-size: 24px;
  line-height: 24px;

  /* Layout */
  margin: 0 24px 0 0;
  padding: 4px 13px 10px 13px;

  @media (--viewportMedium) {
    font-size: 30px;
    line-height: 40px;
    padding: 5px 13px 15px 13px;
    margin: 0;
  }
}

.mobileInput {
  /* Font */
  composes: fontInput;

  /* Layout */
  height: 50px;

  /* Borders */
  border: 0;
  border-radius: 0;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-radius: 0;
    border: 0;
    outline: none;
    box-shadow: none;
  }
}

.desktopIcon {
  border: none;
  padding-top: 2px;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--colorWhite);

  & svg {}
}

.desktopInput {
  composes: p from global;

  /* Font */

  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden;

  margin-top: 0;
  margin-bottom: 0;

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
    border: 0;
    box-shadow: none;
  }

  &:hover::placeholder,
  &:focus::placeholder {
    color: var(--colorGrey700);
  }

  /* Placeholder text might be too wide */
  &::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    transition: var(--transitionStyleButton);
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mobilePredictions {
  position: absolute;
  top: 50px;
  left: 0;
  /* searchContainer margin-top (94px) + mobileInput height (50px) = 144px */
  min-height: calc(100vh - 144px);
  width: 100%;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  @media (--viewportMedium) {
    min-height: unset;
  }
}

.mobilePredictionsAttribution {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.desktopPredictions {
  position: absolute;
  width: 100%;
  top: var(--topbarHeightDesktop);
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: 0;
  max-width: 434px;
}

/**
 * Keyword search
 */
.keywordSearchWrapper {
  position: relative;
  display: flex;
}

.searchSubmit {
  border: 0;
  padding: 0;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;

    & .iconSvgGroup {
      stroke: var(--colorGrey700);
    }
  }
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--colorWhite);
}

.iconSvg {
  margin: auto;
}

.iconSvgGroup {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.SearchFilter {
  display: flex;
  gap: 10px;
  margin-top: 40px;
  align-items: flex-start;

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.root {
  width: 100%;
  max-width: 800px;
  margin: 0;
  /* display: flex;
  align-items: flex-start;
  margin-top: 30px;
  gap: 20px;
  & > button{
    margin: 0;
  }
  & > div{
    margin: 0;
  } */
}

.bottomButton {
  width: 100%;

  & button {
    width: 50%;

    @media(max-width: 500px) {
      width: 100%;
    }
  }
}

.locationAddress {
  margin-top: 30px;
  width: 100%;

  @media (max-width: 767px) {}

  &>div {
    &:first-child {
      display: flex;
      align-items: center;
      background: #fff;
      padding: 0 12px;
      width: 100%;
      min-height: 72px;
      border-radius: 6px;
      border: 2px solid #d8dce6;

      & svg {
        margin: 0;

        & path {
          fill: var(--marketplaceColor);
        }
      }

      &>div {
        &:first-child {
          display: flex;
          align-items: center;
        }
      }

      & div {
        border: 0 !important;
        outline: unset !important;
        border: 0 !important;
        box-shadow: none;
      }

      & span {
        border: 0 !important;
        outline: unset !important;
        border: 0 !important;
        box-shadow: none;
      }

      &>div {
        flex: 1;
      }

      & input {
        border: 0;
        padding: 16px;

        &:focus {
          outline: unset !important;
          border: 0 !important;
          box-shadow: none;
        }
      }
    }
  }
}

.stepsModal {
  & .modalTitle {
    color: #000;
  }

  & .modalBody {
    margin-top: 30px;
    height: 100%;
    /* max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 30px;
    @media (max-width: 1023px) {
      padding-right: 10px;
    }
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
      background: #edf5fa;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--marketplaceColorDark);
      border-radius: 6px;
      outline: 1px solid #fff;
    } */
  }
}

.functionButtons {
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
}

.singleNextBtn {
  width: max-content;
  /* margin-left: auto; */
  padding: 0 20px;
  margin-top: 20px;
}

.singleBackBtn {
  width: max-content;
  /* margin-right: auto; */
  padding: 0 20px;
  margin-top: 20px;
  background-color: #fff;
  color: var(--marketplaceColor);
  border: 1px solid var(--marketplaceColor);

  &:hover {
    color: #fff;
  }
}

.successBg {
  padding: 20px 0;

  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: -39px;
    right: -39px;
    top: -39px;
    bottom: 0;
    background-color: #00800012;
  }

  & svg {
    fill: #7fd67f !important;

    & path {
      &:first-child {
        stroke: #7fd67f;
      }

      &:last-child {
        stroke: #fff;
      }
    }
  }
}

.buttonGroups {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 40px;
}

.locationAutocompleteInput {}

.successMark {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;

  & svg {
    width: 70px;
    height: 70px;
    fill: transparent;
  }
}

.modalTextCenter {
  text-align: center;
  color: #000 !important;
  font-size: 30px;
  line-height: 40px;
  margin: 0;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
}

.modalText {
  color: #000 !important;
  font-size: 16px;
  line-height: 32px;
  margin: 12px 0 4px;
}

.locationAddressForm {
  &>div {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #d8dce6;
    padding-left: 10px;

    & input {
      border: 0;
      padding: 8px 16px;

      &:focus {
        outline: unset !important;
        border: 0 !important;
        box-shadow: none;
      }
    }
  }
}

.viewMatchBtn {
  width: max-content;
  margin-left: auto;
  display: block;
  background: var(--marketplaceColor);
  color: #fff !important;
  padding: 12px;
  margin-top: 24px;
}

.phone,
& .password {
  margin-bottom: 16px;
}

.modalSuccessCenter {
  display: flex;
  color: green !important;
  justify-content: center;
  gap: 10px;
}

.description {
  text-align: center;
  color: #000 !important;
  font-size: 16px;
  line-height: 22px;
  margin: 0;
}

.checkboxInput {
  margin-bottom: 6px;
  display: block;
  border: 1px solid var(--marketplaceColor);
  padding: 0 10px;
  cursor: pointer;

  & label {
    padding: 6px;
    cursor: pointer;
  }
}

.subtitle {
  text-align: left;
  color: #000 !important;
}

.backButton {
  border: 1px solid var(--marketplaceColor);
  background-color: #fff;
  color: var(--marketplaceColor);

  &:hover {
    color: #fff;
  }
}

.radioBtnInput {
  margin-bottom: 8px;
  border: 1px solid var(--marketplaceColor);
  display: block;
  padding: 0 10px;
  cursor: pointer;

  & label {
    padding: 4px;
    cursor: pointer;
  }
}

.input_row {
  display: flex;
  background: #fff;
  align-items: center;
  padding: 0 12px;
  width: 55%;
  min-height: 72px;
  border-radius: 6px;
  border: 2px solid #d8dce6;

  @media (max-width: 767px) {
    width: 100%;
    padding-right: 0;
  }

  & svg {
    margin: 0;
  }

  & div {
    border: 0 !important;
    outline: unset !important;
    border: 0 !important;
    box-shadow: none;
  }

  & span {
    border: 0 !important;
    outline: unset !important;
    border: 0 !important;
    box-shadow: none;
  }

  &>div {
    flex: 1;
    max-width: 419px;
  }

  & input {
    border: 0;
    padding: 16px;

    @media (max-width: 767px) {
      font-size: 14px;
    }

    &:focus {
      outline: unset !important;
      border: 0 !important;
      box-shadow: none;
    }
  }

  & select {
    border: 0;
    padding: 16px;
    box-shadow: none;

    &:focus {
      outline: unset !important;
      border: 0 !important;
      box-shadow: none;
    }
  }
}

.submitButton {
  width: fit-content;
  margin: 30px auto;
  padding: 0 33px;
  background: var(--marketplaceColor);
  color: #fff !important;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.viewButton {
  & a {
    color: pink;
  }
}

.modalCloseBtnHide {
  &>div {
    &>div {
      &>button {
        display: none !important;
      }
    }
  }
}

.termsAndConditions {
  margin-top: 40px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.dateInput {
  margin-top: 20px;
}

.venderError {
  color: #ff0000;
  font-size: 14px;
  font-weight: 600;
}

.inputBox2 {
  &>div {
    &>div {
      &>span+div {
        border-color: #ddd;

        &>div:first-child {
          &>div:not(:last-child) {
            background: #ffffff;
            border-radius: 45px;
            width: max-content;
            display: inline-flex;
            align-items: center;
            padding: 0 4px;
            font-size: 16px;

            @media (max-width: 767px) {
              font-size: 14px;
            }

            & div[role='button'] {
              border: 1.5px solid #98a8b7;
              border-radius: 50%;
              height: 20px;
              width: 20px;

              @media (max-width: 767px) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.mediaWrapper {
  position: relative;
  height: 100%;
}

.modalSearchWrapper {
  /* position: absolute;
  z-index: 10px;
  top: 20%;
  left: 3%;
  right: 3%;
  background-color: #00000062;
  padding: 3%;
  @media (max-width: 767px) {
    top: 20%;
  } */
  height: 900px;
  background-size: 100% 100%;
  background-position: center;
  margin: auto;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.firstPageLayout {
  width: 100%;
  margin: auto;
  height: 500px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  background-size: 100% 100%;
  background-position: center;

  @media(max-width:768px) {
    height: 100%;
  }
}

.media {
  height: 100%;
}

.modalLarge {
  @media (max-width: 767px) {
    align-items: center !important;
    justify-content: center !important;
    background-color: #00000049 !important;
  }

  &>div {
    @media (max-width: 767px) {
      min-height: 50vh !important;
      max-height: 60vh !important;
      width: 97% !important;
    }

    &>div {
      @media (max-width: 767px) {
        &>button {
          position: absolute !important;
          top: 10px !important;
        }

        min-height: 50vh !important;
        max-height: 60vh !important;

        &>div {
          overflow: hidden !important;
          padding-bottom: 0 !important;
        }
      }

      @media (min-width: 768px) {
        flex-basis: 626px !important;
      }
    }
  }
}

.phoneWrapper {
  margin-top: 16px;

  & .phoneLabel {
    @media(--viewportLarge) {
      font-weight: var(--fontWeightSemiBold);
      line-height: 16px;
      margin-top: 0;
      font-size: 13px;
      margin-bottom: 0;
      padding-top: 3px;
      padding-bottom: 13px;
    }
  }

  & .phoneBox {
    & input {
      height: 52px !important;
      width: 100% !important;

      @media (max-width:768px) {
        height: 40px !important;
      }
    }
  }
}

.validateText {
  color: #ff0000;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
}

.bodyContent {
  & .otpVerification {
    text-align: center;

    & span {
      font-size: 26px;
      font-weight: 700;
    }
  }

  & .labelOtp {
    font-size: 15px;
    margin-top: 24px;
  }

}

.optInputWrapper {
  display: flex;
  gap: 12px;
  align-items: center;
}

.optTimer {
  display: flex;
  gap: 50px;
  align-items: center;
  margin-top: 20px;

  & .secondRemain {
    color: #000;
    padding-left: 30px;
  }

  & .resendLine {
    color: #b25f87;
  }
}

.submitOtp {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;

  @media (max-width:768px) {
    min-height: 36px;
  }
}

.actionButtons {
  gap: 20px;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;

  & button {
    width: 100px;
  }
}

.otpText {
  font-size: 16px;
  color: #ff0000;
  text-align: center;
}

.firstLandingModal {
  &>div {
    border-radius: 10px;

    @media(max-width: 768px) {
      justify-content: center;
      align-items: flex-start;
      padding: 0;
      background-color: rgba(0, 0, 0, 0.4) !important;
      background-image: none;
    }

    &>div {
      margin: 0;
      padding: 0 !important;
      border-bottom: none !important;
      border-radius: 10px !important;

      @media(max-width: 768px) {
        flex-basis: 576px;
        flex-grow: 0;
        min-height: auto !important;
        height: 400px !important;
        justify-content: center;
        margin: auto 10px;
      }

      &>button {
        right: 0;

        @media(max-width: 768px) {
          position: absolute;
        }

        @media(max-width:768px) {
          right: 10px;
          top: 10px;
        }

        & svg {
          margin: 0;
          padding: 0;
          width: 20px;
          height: 20px;
          fill: #fff;
        }
      }

      &>div {
        padding-right: 0;

        @media(max-width:768px) {
          padding-bottom: 0;
        }

        &>div {
          @media(max-width:768px) {
            height: 100%;
          }
        }
      }
    }
  }
}

.secondLandingModal {
  &>div {
    border-radius: 10px;

    &>div {
      margin: 0;
      padding: 20px !important;
      border-bottom: none !important;

      &>button {
        right: 0;

        @media(max-width:768px) {
          right: 10px;
          top: 10px;
        }

        & svg {
          margin: 0;
          padding: 0;
        }
      }

      &>div {
        padding-right: 0;

        @media(max-width:768px) {
          padding-bottom: 0;
        }
      }
    }
  }

  & select {
    background-size: 22px;
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%201.2%201.2%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20width%3D%2248%22%20height%3D%2248%22%20fill%3D%22white%22%20fill-opacity%3D%220.01%22%20d%3D%22M0%200H1.2V1.2H0V0z%22%2F%3E%3Cpath%20d%3D%22M0.925%200.45%200.625%200.75%200.325%200.45%22%20stroke%3D%22%23808080%22%20stroke-width%3D%220.1%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
  }
}

.contactCheckbox {
  margin-top: 20px;
}

.locationBox {
  &>div {
    border-bottom: none;
  }
}

.fifthWrapper {
  margin-top: 40px;
}

.privacyCheck {
  font-weight: 400;
}

.otpVerified {
  color: green;
  font-size: 16px;
  text-align: center;
}

.invalidOtp {
  color: red;
  font-size: 16px;
  text-align: center;
}