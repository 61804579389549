.root {}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }

  & ol {
    padding-left: 20px;
    margin-bottom: 10px;
  }
}

:global(#terms_and_service) {
  & ol {
    padding-left: 20px;
    margin-bottom: 0px;
  }

  & .text {
    & p {
      margin: 6px 0 16px;
    }
  }
}

:global(#privacy_policy) {
  & ol {
    padding-left: 20px;
    margin-bottom: 0px;
  }

  & .text {
    & p {
      margin: 6px 0 16px;
    }
  }
}



:global(#browse-by-culture-container) {
  position: relative;

  &>div:nth-child(2) {
    position: relative;
  }

  & .media {
    &>div {
      &>div {
        padding-bottom: 66.5% !important;
      }
    }
  }

  & h3 {
    text-align: center;
  }

  & .text {
    margin-top: 14px;
  }

  & a {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    width: 100%;
    position: absolute;
  }

  & img {
    /* aspect-ratio: 16/16; */
    max-height: 200px;
    object-fit: contain;

    @media(max-width: 768px) {
      object-fit: cover;
    }
  }
}

:global(#section-3-block-1) {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 20px;
  height: 100%;
  background-color: #fff;

  & .text {
    & h3 {
      color: transparent;
      position: relative;
      margin-bottom: 16px;
      height: 45px;

      @media (max-width:767px) {
        margin-bottom: 24px;
      }

      &:before {
        content: '1';
        position: absolute;
        margin-right: 8px;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ddd;
        border-radius: 50%;
        margin-bottom: 20px;
        color: #b25f87;
      }
    }
  }
}

:global(#section-3-block-2) {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 20px;
  height: 100%;
  background-color: #fff;

  & .text {
    & h3 {
      position: relative;
      color: transparent;
      margin-bottom: 16px;
      height: 45px;

      @media (max-width:767px) {
        margin-bottom: 24px;
      }

      &:before {
        content: '2';
        position: absolute;
        margin-right: 8px;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ddd;
        border-radius: 50%;
        margin-bottom: 20px;
        color: #b25f87;
      }
    }
  }
}

:global(#browse-by-culture-slider) {
  & .media {
    max-height: 200px;
  }
}

:global(#section-3-block-3) {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 20px;
  height: 100%;
  background-color: #fff;

  & .text {
    & h3 {
      height: 45px;
      position: relative;
      color: transparent;
      margin-bottom: 16px;

      @media (max-width:767px) {
        margin-bottom: 24px;
      }

      &:before {
        content: '3';
        position: absolute;
        margin-right: 8px;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ddd;
        border-radius: 50%;
        margin-bottom: 20px;
        color: #b25f87;
      }
    }
  }
}

:global(#how-it-work-section-2) {
  &>div {
    padding-bottom: 0;
  }
  @media (min-width: 1200px) {
    & h3 {
      font-size: 2em;
    }

    & p {
      font-size: 18px;
    }

    & img {
      width: 91%;
      height: 74%;
    }
  }
}
:global(#how-it-work-section-21) {
  &>div {
    padding: 20px 0;
    &>div {
      @media(max-width:768px) {
        &>div {
&:first-child {
  order: 1;
}
        }
      }
    }
  }
  @media (min-width: 1200px) {
    & h3 {
      font-size: 2em;
    }

    & p {
      font-size: 18px;
    }

    & img {
      width: 91%;
      height: 74%;
    }
  }
}
:global(#how-it-work-section-22) {
  &>div {
    padding: 20px 0;
    &>div {
      @media(max-width:768px) {
        &>div {
&:last-child {
  order: 1;
}
        }
      }
    }
  }
  @media (min-width: 1200px) {
    & h3 {
      font-size: 2em;
    }

    & p {
      font-size: 18px;
    }

    & img {
      width: 91%;
      height: 74%;
    }
  }
}
:global(#how-it-work-section-23) {
  &>div {
    padding: 20px 0;
    &>div {
      @media(max-width:768px) {
        &>div {
&:first-child {
  order: 1;
}
        }
      }
    }
  }
  @media (min-width: 1200px) {
    & h3 {
      font-size: 2em;
    }

    & p {
      font-size: 18px;
    }

    & img {
      width: 91%;
      height: 74%;
    }
  }
}
:global(#how-it-work-section-24) {
  &>div {
    padding: 20px 0;
    &>div {
      @media(max-width:768px) {
        &>div {
&:last-child {
  order: 1;
}
        }
      }
    }
  }
  @media (min-width: 1200px) {
    & h3 {
      font-size: 2em;
    }

    & p {
      font-size: 18px;
    }

    & img {
      width: 91%;
      height: 74%;
    }
  }
}

:global(#section-customer) {
  @media (min-width: 1200px) {
    & h3 {
      font-size: 2.75em;
      text-align: left;
    }

    & p {
      font-size: 18px;
    }

    & img {
      width: 91%;
      height: 74%;
    }
  }
}

:global(#grow-business) {
  @media (min-width: 1200px) {
    & h3 {
      font-size: 2.75em;
      text-align: left;
    }

    & p {
      font-size: 18px;
    }

    & img {
      width: 91%;
      height: 74%;
    }
  }
}

:global(#other-benefits) {
  @media (min-width: 1200px) {
    & h3 {
      font-size: 2.75em;
      text-align: left;
    }

    & p {
      font-size: 18px;
    }

    & img {
      width: 91%;
      height: 74%;
    }
  }
}

:global(#details-hero) {
  & :global(#description) {
    text-align: center;

    & p {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

:global(#credits) {
  @media (min-width: 1200px) {
    & h3 {
      font-size: 2.75em;
    }

    & p {
      font-size: 18px;
    }

    & img {
      width: 91%;
      height: 74%;
    }
  }
}

:global(#control) {
  @media (min-width: 1200px) {
    & h3 {
      font-size: 2.75em;
    }

    & p {
      font-size: 18px;
    }

    & img {
      width: 91%;
      height: 74%;
    }
  }
}

:global(#job-related) {
  @media (min-width: 1200px) {
    & h3 {
      font-size: 2.75em;
    }

    & p {
      font-size: 18px;
    }

    & img {
      width: 91%;
      height: 74%;
    }
  }
}

:global(#guaranteed) {
  @media (min-width: 1200px) {
    & h3 {
      font-size: 2.75em;
      text-align: left;
    }

    & p {
      font-size: 18px;
    }

    & img {
      width: 91%;
      height: 74%;
      object-fit: contain;
    }
  }
}

:global(#new-business) {
  @media (min-width: 1200px) {
    & h2 {
      font-size: 2.75em;
      text-align: left;
      max-width: 400px;
      text-align: center;
    }

    & p {
      font-size: 18px;
    }

    & img {
      width: 91%;
      height: 74%;
      object-fit: contain;
    }
  }
}

:global(#how-it-works-vendor) {
  @media (min-width: 1200px) {
    & h3 {
      font-size: 2em;
      text-align: left;
    }

    & p {
      font-size: 18px;
    }

    & img {
      width: 91%;
      height: 74%;
      object-fit: cover;
    }
  }
}

:global(#discoverability) {
  @media (min-width: 1200px) {
    & h3 {
      font-size: 2em;
      text-align: left;
    }

    & p {
      font-size: 18px;
    }

    & img {
      width: 91%;
      height: 74%;
      object-fit: contain;
    }
  }
}

:global(#lead-fee) {
  @media (min-width: 1200px) {
    & h3 {
      font-size: 2em;
      text-align: left;
    }

    & p {
      font-size: 18px;
    }

    & img {
      width: 91%;
      height: 74%;
      object-fit: cover;
    }
  }
}

:global(#efficient) {
  @media (min-width: 1200px) {
    & h3 {
      font-size: 2em;
      text-align: left;
    }

    & p {
      font-size: 18px;
    }

    & img {
      width: 91%;
      height: 74%;
      object-fit: contain;
    }
  }
}

:global(#faq-hero) {
  & header {
    display: block;

    & h2 {
      text-align: center;
      max-width: 100%;
    }
  }
}

.buttonExplore {
  max-width: 260px;
  margin: 30px auto 10px;
  display: block;

  & a {
    color: #fff;
  }
}

.aboutDescription {
  & p {
    text-align: justify;
    max-width: 100%;
  }
}

:global(#product-cover){
  & p{
    display: flex;
    &:last-child{
      & em{
        &:last-child{
          width: unset;
          display: unset;
        }
      }  
    }
    & em{
      width: 70px;
      display: block;

      &:first-child{
        width: unset;
        display: unset;
      }
      
      &:nth-child(2){
        margin-right: 20px;
      }
      &:nth-child(3){
        margin-right: 20px;
      }
    }
  }
}
:global(#about-description) {
  &>div {
    &>p {
&:nth-child(3) {
  color: #000;
  font-weight: 700;
  font-size: 22px;
}
    }
  }
}