.layout {
  min-height: 100vh;
  display: block !important;
  grid-template-rows: auto 1fr auto;
}

.topbar {
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.main {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
}

.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullPageLoader {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
:global(#blogs-hero) {
  &>div {
    &:last-child{
      & header {
        &>p {
          font-size: 22px;
          font-weight: 500;
          color: black;
        }
      }
    }
  }
}